import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'

import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
import VanillaTilt from 'vanilla-tilt';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';


// Components

// Swiper
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

window.Swiper = Swiper;
Swiper.use([Autoplay, EffectFade, Pagination, Navigation]);

// Global
window.Alpine = Alpine;
window.VanillaTilt = VanillaTilt;

// gsap
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;
window.ScrollToPlugin = ScrollToPlugin;
window.VanillaTilt = VanillaTilt;


// Inits
Alpine.plugin(collapse);
Alpine.start();

// Dark / Light trigger

function darkLightTrigger() {

    const darkSections = document.querySelectorAll('.js-dark-trigger');

    let scrollPos = window.scrollY;

    darkSections.forEach(section => {						

        let sectionBound = section.getBoundingClientRect();
        let sectionTop = sectionBound.top + scrollPos;
        let sectionBottom = sectionBound.bottom + scrollPos;

        if (scrollPos > sectionTop - (window.innerHeight * 0.8) && scrollPos < sectionBottom - (window.innerHeight * 0.8)) {
            document.documentElement.classList.add('dark');
        }

    });

    const lightSections = document.querySelectorAll('.js-light-trigger');

    lightSections.forEach(section => {
        
        let sectionBound = section.getBoundingClientRect();
        let sectionTop = sectionBound.top + scrollPos;
        let sectionBottom = sectionBound.bottom + scrollPos;

        if (scrollPos > sectionTop - (window.innerHeight * 0.8) && scrollPos < sectionBottom - (window.innerHeight * 0.8)) {            
            document.documentElement.classList.remove('dark');
        }

    });

}

window.addEventListener('scroll', () => {
    darkLightTrigger();
});
